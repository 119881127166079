import React from "react";
import Header from "../components/global/Header";
import { Outlet, Link, useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  return <Header id="transparent"/>;
};

export default Layout;
