import React, { useState, useEffect } from "react";
import Button from "../../components/global/Button";

const ServicesSection = (data) => {
  const [slides, setSlides] = useState(data.slideData);
  const [currentIndex, setCurrentIndex] = useState(1);

  const imageClickHandler = (idx) => {
    // Update the currentIndex based on the clicked image
    setCurrentIndex(idx);
  };

  return (
    <div id={data.id} className={data.class}>
      <div className="services-left">
        <div className={`service-container-header flex-row `}>
          <div className="">
            <span className="small-header fs-3">Services</span>
            <br />
            <h2 className="section-name fs-6">{data.serviceName}</h2>
          </div>
        </div>
        <hr className="mb-3 " />
        <div className="content-container flex-col">
          <p className="fs-0">{data.pContent}</p>

        </div>
      </div>
      <div className="services-right">
        {/* <CubeCarousel data={data.slideData.slides} /> */}
        <div className="image-container">
          {slides.map((item, idx) => {
            const className =
              idx === currentIndex
                ? "current"
                : idx === (currentIndex + 1) % slides.length
                ? "next"
                : "prev";

            return (
              <img
                onClick={() => imageClickHandler(idx)}
                className={`img-${className}`}
                src={item.src}
                key={idx}
              />
            );
          })}
          {/* {slides.map((item, idx) => {
            return (
              <img
                onClick={imageClickHandler()}
                class={`img-${
                  idx === 0 ? "prev" : idx === 1 ? "current" : "next"
                }`}
                src={item.src}
              />
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
