import React, { useState } from "react";
import ContactForm from "../components/global/ContactForm";
import Layout from "./Layout";
import { Helmet } from "react-helmet";


const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Craftsman - Contact - International Retail Specialists
        </title>
        <meta
          name="description"
          content="Reach out with questions or comments via our form or email us at craftman@emirates.net.ae. Call us at +971 67457 146."
        />
        
        <meta
          property="og:title"
          content="Craftsman - Contact - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Reach out with questions or comments via our form or email us at craftman@emirates.net.ae. Call us at +971 67457 146."
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta
          property="og:url"
          content="https://craftsman-llc.com/contact"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="contact-page">
        <Layout />
        <div className="container-xl pt-5 mx-auto">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
