import React from "react";

const Close = (props) => {
  return (
    <svg id="sidebarClose" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={"15px"} width={"15px"}>
      <path
        fill={props.fill}
        stroke="none"
        d="M186 255c-5.87-4.21-12.71-11.71-18-17l-30-30-93-93-20-20C11.98 81.98 2.23 72.79 2 53 1.68 24.39 22.03 2.57 51 2.57c29.78 0 41.97 23.49 62 41.15L165 95l91 91c4.21-5.87 11.71-12.71 17-18l30-30 92-92 19-19c13.35-13.35 23.03-22.97 43-23h5c25.6.12 45.42 21.9 45.99 47 .62 27.41-18.33 40.34-35.99 58L372 209l-33 33c-2.39 2.39-10.96 9.9-10.96 13 0 3.14 9.46 11.5 11.96 14l35 35 76 75.99L474 402c10.49 10.49 29.16 26.78 34.55 40 3.83 9.4 3.56 14.19 3.45 24-.2 16.69-11.69 33.09-27 39.54-9.34 3.93-16.19 3.89-26 3.5-10.26-.42-18.26-2.33-27-8.14-5.17-3.45-16.02-14.92-21-19.9l-38-38-118-118c-4.21 5.87-11.71 12.71-17 18l-30 30-93 93-20 20c-13.73 13.73-22.27 22.97-43 23-8.42.01-13.72.49-22-2.04C14.23 502.15 2.65 488.3.15 472v-13c.07-18.35 11.71-29.86 23.85-42l23-23 139-139Z"
      />
    </svg>
  );
};

export default Close;
