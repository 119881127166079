import React from "react";

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.875"
      height="34.664"
      viewBox="0 0 34.875 34.664"
    >
      <path
        id="Icon_awesome-facebook"
        data-name="Icon awesome-facebook"
        d="M35.438,18A17.438,17.438,0,1,0,15.275,35.227V23.041h-4.43V18h4.43V14.158c0-4.37,2.6-6.784,6.586-6.784a26.836,26.836,0,0,1,3.9.34V12h-2.2a2.52,2.52,0,0,0-2.841,2.723V18h4.836l-.773,5.041H20.725V35.227A17.444,17.444,0,0,0,35.438,18Z"
        transform="translate(-0.563 -0.563)"
      />
    </svg>
  );
};

export default Facebook;
