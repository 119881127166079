import React from "react";
import { Link, Outlet } from "react-router-dom";

const Dropdown = (props) => {
  return (
    <ul className="flex-col">
      <li onClick={props.toggleSidebar}>
        <Link to="/portfolio/pop-up">Pop Up</Link>
      </li>
      <li onClick={props.toggleSidebar}>
        <Link to="/retail-displays">Retail Displays</Link>
      </li>
      <li onClick={props.toggleSidebar}>
        <Link to="/hpp-dressups">HPP / Dress Ups</Link>
      </li>
    </ul>
  );
};

export default Dropdown;
