import React from "react";

const WaveTop = (data) => {
  return (
    <svg
      viewBox="0 0 500 200"
      preserveAspectRatio="xMinYMin slice"
      className={data.id}
      fill={data.colour}
    >
      <path d="M0 30c150 70 280-30 500-10V0H0" />
    </svg>
  );
};

export default WaveTop;
