import React, { useState } from "react";
import Layout from "../Layout";
import Footer from "../../components/global/Footer";
import podiumsData from "../../data/podiumsData.json";
import PodiumSection from "../../components/global/PodiumSection";
import ImageGrid from "../../components/global/ImageGrid";
import { Helmet } from "react-helmet";
import Close from "../../components/icons/Close";

const images = [
  "/images/podiumsImages/podium (1).webp",
  "/images/podiumsImages/podium (2).webp",
  "/images/podiumsImages/podium (7).webp",
  "/images/podiumsImages/podium (4).webp",
  "/images/podiumsImages/podium (5).webp",
  "/images/podiumsImages/podium (6).webp",
];

const Podiums = () => {
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const removeModel = (bool) => {
    setModel(bool);
    setTempImgSrc("");
  };
  return (
    <>
      <Helmet>
        <title>
          Craftsman - Retail Displays - International Retail Specialists
        </title>
        <meta
          name="description"
          content="Discover our stunning portfolio showcasing bespoke retail displays and promotional activations crafted for top brands. Contact us to elevate your retail presence!"
        />

        <meta
          property="og:title"
          content="Craftsman - Retail Displays - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Discover our stunning portfolio showcasing bespoke retail displays and promotional activations crafted for top brands. Contact us to elevate your retail presence!"
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta
          property="og:url"
          content="https://craftsman-llc.com/retail-displays"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div
        onClick={() => removeModel(false)}
        className={model ? "model open" : "model"}
      >
        <img src={tempImgSrc} />
        <div onClick={() => removeModel(false)}>
          <Close fill={"#fff"} />
        </div>
      </div>
      <div className="podium-container">
        <Layout />
        <div className="flex container-xl mx-auto flex-col">
          <h2 className="capitalize fs-7 mb-5">Retail Displays</h2>
          <div className="flex-col gap-10">
            {podiumsData.slides.map((slide, index) => (
              <PodiumSection slide={slide} index={index} />
            ))}
          </div>
          <ImageGrid>
            {images.map((src, index) => (
              <img
              className="pointer"
                key={index}
                onClick={() => getImg(src)}
                src={src}
                alt={`Dress up ${index + 1}`}
              />
            ))}
            {/* <img src="/images/podiumsImages/podium (1).webp" />
            <img src="/images/podiumsImages/podium (2).webp" />
            <img src="/images/podiumsImages/podium (7).webp" />
            <img src="/images/podiumsImages/podium (4).webp" />
            <img src="/images/podiumsImages/podium (5).webp" />
            <img src="/images/podiumsImages/podium (6).webp" /> */}
          </ImageGrid>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Podiums;
