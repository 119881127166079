import React from "react";
import PortfolioSection from "../components/portfolio-page/PortfolioSection";
import Slides from "../data/recentProjectsData.json";
import WaveTop from "../components/assets/WaveTop";
import Footer from "../components/global/Footer";
import Layout from "./Layout";

const Portfolio = (data) => {
  const popUpDesc =
    "With our retail pop-up stands, you can create a unique shopping experience for your customers and showcase your products in the best possible light. Whether you're looking to launch a new product, test a new market, or simply attract more foot traffic, our pop-up stands are the perfect solution.";
  return (
    <>
      <div className="portfolio-page-container">
      <Layout />
        <div className="white-container">
          <PortfolioSection
            slides={Slides.slides}
            desc={popUpDesc}
            title={"Pop Up"}
          />
        </div>
        <div>
          <div className="wave-container">
            <WaveTop colour={"#fff"} class={"wave-top"} />
          </div>
          <PortfolioSection
            slides={Slides.slides}
            desc={popUpDesc}
            title={"Promosite"}
            color={"white"}
            class={"reverse"}
          />
        </div>
        <div className="white-container">
          <div className="wave-container">
            <WaveTop colour={"#55A4E0"} class={"wave-top"} />
          </div>
          <PortfolioSection
            slides={Slides.slides}
            desc={popUpDesc}
            title={"Circle"}
          />
        </div>
        <div>
          <div className="wave-container">
            <WaveTop colour={"#fff"} class={"wave-top"} />
          </div>
          <PortfolioSection
            slides={Slides.slides}
            desc={popUpDesc}
            color={"white"}
            class={"reverse"}
            title={"HPP"}
          />
        </div>
        <div className="white-container">
          <div className="wave-container">
            <WaveTop colour={"#55A4E0"} class={"wave-top"} />
          </div>
          <PortfolioSection
            slides={Slides.slides}
            desc={popUpDesc}
            title={"Dress Ups"}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
