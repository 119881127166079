import React from "react";

const Menu = (props) => {
  return (
    <svg
      className="menu-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        id="Selection"
        onClick={props.handleClick}
        fill="#fff"
        stroke="none"
        strokeWidth="1"
        d="M 81.00,120.53
           C 81.00,120.53 120.00,120.00 120.00,120.00
             120.00,120.00 194.00,120.00 194.00,120.00
             194.00,120.00 423.00,120.00 423.00,120.00
             429.73,120.01 434.32,120.45 439.91,124.79
             450.36,132.90 450.87,152.48 441.67,161.67
             436.22,167.13 430.27,167.99 423.00,168.00
             423.00,168.00 89.00,168.00 89.00,168.00
             81.73,167.99 75.78,167.13 70.33,161.67
             60.39,151.73 59.87,126.83 81.00,120.53 Z
           M 81.00,232.53
           C 81.00,232.53 120.00,232.00 120.00,232.00
             120.00,232.00 194.00,232.00 194.00,232.00
             194.00,232.00 423.00,232.00 423.00,232.00
             430.27,232.01 436.22,232.87 441.67,238.33
             450.87,247.52 450.36,267.10 439.91,275.21
             434.32,279.55 429.73,279.99 423.00,280.00
             423.00,280.00 89.00,280.00 89.00,280.00
             81.73,279.99 75.78,279.13 70.33,273.67
             60.39,263.73 59.87,238.83 81.00,232.53 Z
           M 81.00,344.53
           C 81.00,344.53 120.00,344.00 120.00,344.00
             120.00,344.00 194.00,344.00 194.00,344.00
             194.00,344.00 423.00,344.00 423.00,344.00
             429.73,344.01 434.32,344.45 439.91,348.79
             450.36,356.90 450.87,376.48 441.67,385.67
             436.22,391.13 430.27,391.99 423.00,392.00
             423.00,392.00 89.00,392.00 89.00,392.00
             81.73,391.99 75.78,391.13 70.33,385.67
             60.39,375.73 59.87,350.83 81.00,344.53 Z"
      />
    </svg>
  );
};

export default Menu;
