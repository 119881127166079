import React from "react";
import Button from "../global/Button";

const HomepageAbout = () => {
  return (
    <div className="flex-col">
      <div>
        <span className="small-header fs-3">About</span>
        <br />
        <h2 className="section-name fs-6">Craftsman</h2>
      </div>

      <div className="about-content">
        <div className="about-text">
          <p className="fs-0">
            <b>Welcome to Craftsman</b>, regional leaders in the international
            in the international duty-free market.
            <br />
            With a footprint that spans across EMEA, our facility is equipped to
            produce premium retail units and displays. Complete in-house
            manufacturing ensures high-end quality, delivering solutions for
            bespoke furniture’s and shopfitting’s. Catering to the unique needs
            of our clients on time.
          </p>
          <Button
            link={"/about"}
            textColour={"#fefefe"}
            backgroundColor={"#667190fe"}
            text={"LEARN MORE"}
          />
        </div>
        <div className="image-container">
          <img src="/images/homepageAbout.webp" />
        </div>
      </div>
    </div>
  );
};

export default HomepageAbout;
