import React, { useState } from "react";
import { useForm } from "react-hook-form";
import GoogleMaps from "../assets/GoogleMaps";
import Mail from "../icons/Mail";
import Phone from "../icons/Phone";
import emailjs from "emailjs-com";
import Linkedin from "../icons/Linkedin";
import WhatsApp from "../icons/WhatsApp";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yber171", // Replace with your actual service ID
        "template_nt4fdxu", // Replace with your actual template ID
        e.target,
        "jXjKCclx89Mbp_Mqp" // Replace with your actual user ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true); // Set submitted to true after successful submission
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  // if (submitted) {
  //   return (
  //     <>
  //       <h2>Thank you!</h2>
  //       <div>We'll be in touch soon.</div>
  //     </>
  //   );
  // }

  return (
    <div className="contact-page-container flex-col">
      {submitted ? (
        <div>
          <h2>Thank you!</h2>
          <div>We'll be in touch soon.</div>
        </div>
      ) : (
        <div className="contact-left">
          <h2 className="section-name fs-6">Contact Us</h2>
          <p className="fs-1">
            If you have any questions or comments, please fill out the form
            below, and we will get back to you as soon as possible.
          </p>
          <form
            id="contact-form"
            className="flex-col"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              className="input-field"
              type="text"
              placeholder="First name"
              {...register("first_name", { required: true, maxLength: 80 })}
            />
            {errors.first_name && (
              <p className="error-msg">This field is required</p>
            )}
            <input
              className="input-field"
              type="text"
              placeholder="Last name"
              {...register("last_name", { required: true, maxLength: 100 })}
            />
            {errors.last_name && (
              <p className="error-msg">This field is required</p>
            )}
            <input
              className="input-field"
              type="text"
              placeholder="Email"
              id="user_email"
              {...register("user_email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.email && (
              <p className="error-msg">Please enter a valid email address</p>
            )}
            <input
              className="input-field"
              type="tel"
              placeholder="Mobile number"
              id="user_number"
              {...register("user_number", {
                required: true,
                minLength: 6,
                maxLength: 12,
              })}
            />
            {errors.mobile_number && (
              <p className="error-msg">
                Please enter a valid mobile number (6-12 digits)
              </p>
            )}
            <textarea
              className="input-field"
              placeholder="Message ... "
              id="message"
              {...register("message", { required: true })}
            />
            {errors.message && (
              <p className="error-msg">This field is required</p>
            )}
            <input
              style={{ color: "#fff", backgroundColor: "#667190fe" }}
              className="primary-btn"
              type="submit"
              value="Submit"
            />
          </form>
        </div>
      )}
      <div className="contact-right flex-col">
        <div className="google-maps">
          <GoogleMaps />
        </div>
        <div className="contact-details flex-col">
          <div>
            <Mail />
            <a href="mailto:craftman@emirates.net.ae">
              craftman@emirates.net.ae
            </a>
          </div>
          <div>
            <Phone />
            <a href="tel:+971 67457 146">+971 67457 146</a>
          </div>
          <div className="contact-linkedin">
            <Linkedin />
            <a href="#">Craftsman Linkedin</a>
          </div>
          <div className="contact-whatsapp">
            <WhatsApp />
            <a href="#">Craftsman</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
