import React from "react";

const AboutTop = () => {
  return (
    <div id="about-1" className="about-hero flex-col">
      <div className="centered about-grid-top">
        <div className="content">
          <h2 className="fs-5">We Are Craftsman</h2>
          <p className="fs-0" id="mission-statement">
            For over 35 years, we've had the privilege of working alongside some
            of the world's largest and most prestigious brands across multiple
            regions. It has been our pleasure to collaborate closely with our
            clients and partners in the International Travel Retail industry,
            contributing to the creation of numerous remarkable designs that
            resonate globally.
            <br />
            <br />
            <h3 className="fs-2">What makes Craftsman different?</h3>
            Our expertise extend beyond solely manufacturing. Offering unmatched
            customer service from our team, ensuring our clients piece of mind.
            With an agile and flexible approach, we adaptively navigate all
            areas of possibilities, addressing all our clients' inquiries.
            Utilizing our extensive network, we can guarantee on-time roll outs
            for all our projects. Using our open book policy, we keep our
            clients in the loop throughout the projects entirety.
            <br />
            <br />
          </p>
        </div>

        <div className="image-container">
          <img src="/images/aboutImages/aboutTop.webp" />
        </div>

        <div id="image-left" className="image-container">
          <img src="/images/aboutImages/showcase9.webp" />
        </div>

        <p className="fs-0 justify">
          <h3 className="fs-2">How do we deliver ?</h3>
          <ul className="pl-3 flex-col gap-2">
            <li className="fs-0">Years of industry experience.​</li>
            <li className="fs-0">Technical expertise.</li>
            <li className="fs-0">Focusing on brand design and detail.​</li>
            <li className="fs-0">Insight into strategic retail solutions.​</li>
            <li className="fs-0">Specialization in retail markets.​​</li>
            <li className="fs-0">Deep understanding of local and regional landscape.​</li>
            <li className="fs-0">Agile and adaptable.​</li>
            <li className="fs-0">Cost-effective, uniquely tailored and flawlessly executed.​</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default AboutTop;



/**With years of industry expertise under our belt, we've honed our
          understanding of customer appeal. By meticulously focusing on design
          and detail, we relish the opportunity to collaborate with our
          partners, providing invaluable insights into bespoke retail solutions.
          Our technical expertise allows us to offer strategic input, ensuring
          the creation of projects that not only captivate but also deliver
          maximum impact.
          <br />
          <br />
          Our specialization lies in crafting temporary, semi-permanent, and
          permanent retail furniture, with a particular focus on the dynamic
          landscape of travel retail. Rooted in our local market expertise, we
          possess a keen understanding of the environments in which we operate.
          Given the fast-paced nature of our industry, our projects demand
          agility and adaptability. We take pride in delivering high-quality
          solutions that are controlled, cost-effective, and uniquely tailored
          to each market, earning the trust of our clients to flawlessly execute
          their activations. */