import React from 'react'
import Button from '../global/Button';

const ImageGrid = ({children, isButton = false}) => {
  return (
    <div id="about-3" className="my-15">
        <div className="grid-gallery relative">
            {children}
            {
              isButton && (
              <div className='button-container absolute right-1 bottom-1 w-fit ml-auto'>
                  <Button
                      textColour={"#667190fe"}
                      link={"/portfolio/pop-up"}
                      backgroundColor="#fefefe"
                      text="See Portfolio"
                  />
              </div>
              )
            }
        </div>
    </div>
  )
}

export default ImageGrid