import React from "react";

const AboutIcon = ({ title, src, desc, onClickHandler, active }) => {
  return (
    <div
      className={`item tooltip ${active ? "active" : ""}`}
      onClick={() => onClickHandler(title, desc)}
    >
      <div className="flex-col justify-content-center align-items-center">
        <img src={src} alt={title} />
        <h3 className="tooltip-title">{title}</h3>
      </div>
        <span class="tooltiptext">{desc}</span>
      <div className="tooltip-desc">
        <h3 className="tooltip-title-2">{title}</h3>
        <span class="tooltiptext">{desc}</span>
      </div>
    </div>
  );
};

export default AboutIcon;
