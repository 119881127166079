import React from "react";
import { Link, Outlet } from "react-router-dom";

const Button = (props) => {
  return (
    <Link to={props.link}>
      <button
        style={{ backgroundColor: props.backgroundColor }}
        className="primary-btn"
      >
        <span style={{ color: props.textColour }}>{props.text}</span>
      </button>
    </Link>
  );
};

export default Button;
