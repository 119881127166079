import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import Button from "../global/Button";

const PortfolioSection = (data) => {
  const [width, setWidth] = useState(window.innerWidth);
  const slidesCount = data.slides.length;
  const buttonText = "See " + data.title;
  const gallery = data.slides.slice(0, 4);
  const classname = data.class + " portfolio-section flex-col";
  let link = data.title;
  link = link.replace(" ", "-");

useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    //Init Swiper
    const swiperMobilePortfolio = new Swiper(".portfolio-swiper", {
      slidesPerView: "auto",
      centeredSlides: true,
      direction: "horizontal",
      initialSlide: slidesCount / 2,
      freeMode: true,
      spaceBetween: 25,
      slideToClickedSlide: true,
    });
  }, []);

  return (
    <>
      {width > 0 ? (
        <div id={data.id} style={{ color: data.color }} className={classname}>
          <h2 className="portfolio-title">{data.title}</h2>
          <p className="portfolio-desc">{data.desc}</p>
          <div className="portfolio-swiper">
            <div className="swiper-wrapper">
              {data.slides.map((item, idx) => {
                return (
                  <div className="swiper-slide" key={idx}>
                    <div className="image-container">
                      <img src={item.src} alt={item.alt} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            text={buttonText}
            link={"/portfolio/" + link}
            backgroundColor={"#fff"}
            textColour={"#55A4E0"}
          />
        </div>
      ) : (
        <div id={data.id} style={{ color: data.color }} className={classname}>
          <div className="flex-col">
            <div className="flex-col content">
              <h2 className="portfolio-title">{data.title}</h2>
              <p className="portfolio-desc">{data.desc}</p>
            </div>
            <div className="gallery flex-row">
              {gallery.map((item, idx) => {
                return (
                  <div className="image-container">
                    <img src={item.src} alt={item.alt} />
                  </div>
                );
              })}
              <Button
                text={buttonText}
                link={"/portfolio/" + link}
                backgroundColor={"#fff"}
                textColour={"#55A4E0"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioSection;
