import React, { useState, useEffect } from "react";
import AboutTop from "../components/about-page/AboutTop";
import Layout from "./Layout";
import ContactForm from "../components/global/ContactForm";
import Wave from "../components/assets/WaveTop";
import { Helmet } from "react-helmet";

import AboutIcon from "../components/about-page/AboutIcon";
import Footer from "../components/global/Footer";
import Button from "../components/global/Button";
import ImageGrid from "../components/global/ImageGrid";

const OfferData = {
  SiteSurvey:
    "All enquiries are followed by a site visit to conduct a survey inspection prior to the start of any project. This way we ensure all drawings and designs are accurate and achievable within the given area. ",
  ProjectManagement:
    "Once the handover for the project has been accepted, the project manager will become the sole point of contact for the entire scope. Giving the client trusted and reliable communication throughout the project’s development, production, and execution. ",
  ThreeDDesign:
    "Highly skilled team in 3D design and CAD drawing. Working with customers to provide detailed rendering and technical drawings for submission. ",
  SkilledFab:
    "Fully trained workforce across all aspects of joinery. All works are completed in house at our well-equipped facility, capable for all manner of production. ",
  ProjectInstallation:
    "Dedicated team for all installations and removals of projects alongside well experienced site supervisors who are onsite for all our activations, regardless of the location. ",
};

const About = () => {
  const [selectedTitle, setSelectedTitle] = useState(null);

  const clickHandler = (title) => {
    setSelectedTitle(title);
    // Add any other logic you want to perform on click
  };
  return (
    <>
      <Helmet>
        <title>Craftsman - About - International Retail Specialists</title>
        <meta
          name="description"
          content="Craftsman provides comprehensive end-to-end services for quality point-of-sale retail solutions, including site surveys, project management, 3D design, skilled fabrication, and project installation."
        />
        
        <meta
          property="og:title"
          content="Craftsman - About - International Retail Specialists"
        />
        <meta
          property="og:description"
          content="Craftsman provides comprehensive end-to-end services for quality point-of-sale retail solutions, including site surveys, project management, 3D design, skilled fabrication, and project installation."
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta property="og:url" content="https://craftsman-llc.com/about" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="about-page-container flex-col">
        <Layout />
        <div className=" container-xl mx-auto">
          <AboutTop />
          <ImageGrid isButton>
            <img src="/images/popUpImages/ArmaniPriv (1).webp" />
            <img src="/images/popUpImages/ArmaniPriv (2).webp" />
            <img src="/images/popUpImages/Lacome Iris/lacome (8).webp" />
            <img src="/images/popUpImages/Lacome Eid/lacomeEID (3).webp" />
            <img src="/images/popUpImages/Beauty Avenue/BeautyAvenue (2).webp" />
            <img src="/images/popUpImages/Giorgio Armani Code/GiorgioArmaniCode (1).webp" />
          </ImageGrid>
          <div id="about-2" className="about-what mt-10">
            <div className="content flex-col">
              <div className="left flex-col">
                <h2>What do we do ?</h2>
                <p className="fs-0">
                  We provide a comprehensive end-to-end service that equips our
                  clients with quality point-of-sale retail solutions, essential
                  for showcasing their products in duty-free stores worldwide.
                  Whether it's sampling tables, promotional product displays,
                  permanent fixtures, or pop-up stores, our craftsmanship is
                  renowned for its durability and longevity, ensuring lasting
                  impressions for our clients' brands.
                </p>
                <br />
                <p className="fs-0">
                  At the heart of our expertise lies the manufacturing of luxury
                  goods, utilizing premium materials and meticulous assembly
                  techniques. Catering to a diverse clientele, we extend our
                  services to supermarkets, malls, and duty-free stores spanning
                  the Middle East and Southwest Asia. Our primary focus remains
                  on delivering bespoke, tailor-made furniture solutions,
                  coupled with unparalleled professionalism and dedication to
                  excellence.
                </p>
              </div>
              <div className="image-container">
                <img src="/images/aboutImages/stock2.webp" />
              </div>
            </div>
          </div>
          <div className="about-services mt-10">
            <div className="content">
              <h2>What do we offer?</h2>
              <div className="about-services-icons">
                <AboutIcon
                  title="Site Survey"
                  src="/images/aboutImages/Site_Survey2.webp"
                  onClickHandler={clickHandler}
                  active={selectedTitle === "Site Survey"}
                  desc={OfferData["SiteSurvey"]}
                />
                <AboutIcon
                  title="Project Management"
                  src="/images/aboutImages/ProjectManage2.webp"
                  onClickHandler={clickHandler}
                  active={selectedTitle === "Project Management"}
                  desc={OfferData["ProjectManagement"]}
                />
                <AboutIcon
                  title="Design Development"
                  src="/images/aboutImages/3DDesign2.webp"
                  onClickHandler={clickHandler}
                  active={selectedTitle === "3D Design"}
                  desc={OfferData["ThreeDDesign"]}
                />
                <AboutIcon
                  title="Skilled Fabrication"
                  src="/images/aboutImages/SkilledFab2.webp"
                  onClickHandler={clickHandler}
                  active={selectedTitle === "Skilled Fabrication"}
                  desc={OfferData["SkilledFab"]}
                />
                <AboutIcon
                  title="Project Installation"
                  src="/images/aboutImages/installation2.webp"
                  onClickHandler={clickHandler}
                  active={selectedTitle === "Project Installation"}
                  desc={OfferData["ProjectInstallation"]}
                />

                {/* <div className="item flex-col">
                  <img
                    // onMouseEnter={() => clickHandler("SiteSurvery")}
                    src="/images/aboutImages/Site_Survey.png"
                  />
                  <h3>Site Survey</h3>
                </div>
                <div className="item flex-col">
                  <img
                    onClick={() => clickHandler("ProjectManagement")}
                    src="/images/aboutImages/ProjectManage.png"
                  />
                  <h3>Project Management</h3>
                </div>
                <div className="item flex-col">
                  <img
                    onClick={() => clickHandler("ThreeDDesign")}
                    src="/images/aboutImages/3DDesign.png"
                  />
                  <h3>3D Design</h3>
                </div>
                <div className="item flex-col">
                  <img
                    onClick={() => clickHandler("SkilledFab")}
                    src="/images/aboutImages/SkilledFab.png"
                  />
                  <h3>Skilled Fabrication</h3>
                </div>
                <div className="item flex-col">
                  <img
                    onClick={() => clickHandler("ProjectInstallation")}
                    src="/images/aboutImages/installation.png"
                  />
                  <h3>Project Installation</h3>
                </div> */}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <ContactForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
