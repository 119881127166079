import React, { useState } from "react";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";

const PodiumSection = ({ slide, index }) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const imageClickHandler = (index) => {
    setActiveIndex(index);
  };
  
  const nextIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
  };

  const prevIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 5) % 6);
  };
  
  return (
    <div className={`slide flex-${index % 2 ? "row-reverse" : "row"} gap-10`}>
      <div className="flex-col podium-left">
        <h3 className="fs-5">{slide.title}</h3>
        <p className="fs-0">{slide.desc}</p>
      </div>

      <div className="podium-right">
        <div class="pagination-arrows">
          <div className="prev-arrow" onClick={() => prevIndex()}>
            <ArrowLeft />
          </div>
          <div className="next-arrow" onClick={() => nextIndex()}>
            <ArrowRight/>
          </div>
        </div>
        {/* <div className="image-container">
          <img
            className={
              activeIndex === 0
                ? "active"
                : activeIndex === 1
                ? "prev"
                : activeIndex === 2
                ? "next"
                : ""
            }
            onClick={() => imageClickHandler(0)}
            src={slide.src[0]}
            alt={`Slide 1 - ${slide.title}`}
          />
          <img
            className={
              activeIndex === 2
                ? "active"
                : activeIndex === 0
                ? "prev"
                : activeIndex === 1
                ? "next"
                : ""
            }
            onClick={() => imageClickHandler(2)}
            src={slide.src[2]}
            alt={`Slide 3 - ${slide.title}`}
          />

          <img
            onClick={() => imageClickHandler(1)}
            className={
              activeIndex === 1
                ? "active"
                : activeIndex === 2
                ? "prev"
                : activeIndex === 0
                ? "next"
                : ""
            }
            src={slide.src[1]}
            alt={`Slide 2 - ${slide.title}`}
          />
        </div> */}
        <div className="image-container">
          {slide.src.map((src, index) => (
            <img
              key={index}
              className={
                activeIndex === index
                  ? "active"
                  : activeIndex === (index + 1) % 6
                  ? "next"
                  : activeIndex === (index + 5) % 6
                  ? "prev"
                  : ""
              }
              onClick={() => imageClickHandler(index)}
              src={src}
              alt={`Slide ${index + 1} - ${slide.title}`}
            />
          ))}
        </div>

      </div>
    </div>
  );
};

export default PodiumSection;
