import React from "react";

const DropdownArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={"10px"}
      width={"10px"}
      className="dropdown-arrow"
      viewBox="0 0 512 512"
    >
      <path
        fill={props.fill}
        stroke="none"
        d="M28 110.46c20.96-2.9 28.89 4.43 43 18.54l18 18 118 118 35 35c2.5 2.5 10.86 11.96 14 11.96 3.14 0 11.5-9.46 14-11.96l36-36 115-115 19-19c12.89-12.89 18.82-20.22 38-20 14.65.18 28.22 9.83 32.56 24 1.58 5.16 1.5 8.72 1.44 14-.19 15.57-8.73 22.73-19 33l-32 32-142 142-25 25c-13.73 13.73-19.39 22.24-40 22-15.36-.18-22.89-8.89-33-19l-32-32L47 209l-25-25C8.27 170.27-.24 164.61 0 144c.2-16.99 11.61-29.88 28-33.54Z"
      />
    </svg>
  );
};

export default DropdownArrow;
