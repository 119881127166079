import React from "react";
import Button from "../global/Button";

const HomepageServicesContent = (data) => {
  return (
    <div className={data.class}>
      <div className="word-swap">
        <span className="small-header white fs-3">Services</span>
        <br />
        <h2 className="section-name large-header white fs-6">{data.header}</h2>
      </div>
      <hr/>
      <br/>
      <p className="word-swap-slow fs-0">{data.paragraph}</p>
      <div className="btn word-swap-slow">
        <Button
          link={"/services"}
          text={"LEARN MORE"}
          backgroundColor={"#667190fe"}
          textColour={"#fefefe"}
        />
      </div>
    </div>
  );
};

export default HomepageServicesContent;
