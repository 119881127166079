import React, { useState } from "react";
import DropdownArrow from "../icons/DropdownArrow";
import Dropdown from "./Dropdown";
import Close from "../icons/Close";
import { CSSTransition } from "react-transition-group";
import { Link, Outlet } from "react-router-dom";

const Sidebar = (props) => {
  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  function toggleDropdown() {
    setDropdownVisibility(!isDropdownVisible);
  }

  return (
    <>
      <div className="underlay-background"></div>
      <div className="sidebar">
        <div onClick={props.toggleSidebar}>
          <Close />
        </div>
        <ul id="sidebarUl">
          <li onClick={props.toggleSidebar}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={props.toggleSidebar}>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="#">
              <span onClick={props.toggleSidebar}>Portfolio</span>
            </Link>
            <span onClick={toggleDropdown} style={{ paddingLeft: "1rem" }}>
              <DropdownArrow />
            </span>
          </li>
          <CSSTransition
            in={isDropdownVisible}
            timeout={300}
            classNames="dropdown-transition"
            unmountOnExit
          >
            <div className="dropdown">
              <Dropdown toggleSidebar={props.toggleSidebar} />
            </div>
          </CSSTransition>
          <li onClick={props.toggleSidebar}>
            <Link to="/about">About</Link>
          </li>
          <li onClick={props.toggleSidebar}>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
